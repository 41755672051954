import { useContext } from "react";

/**
 * Imports types
 */
import { Paths } from "./useNavigation.types";

/**
 * Imports the context
 */
import { context } from "./Context";

/**
 * Imports the provider
 */
import { NavigationProvider } from "./Provider";

/**
 * Imports hooks
 */
import { useHistory } from "react-router-dom";
import { useUserUtils, useLocation } from "..";

/**
 * Defines the main hook
 */
const useNavigation = () => {
  /**
   * Gets the context value
   */
  const {
    expanded,
    switchingAccount,
    scrollContainerId,
    toggleMenu,
    setExpanded,
    closeSwitchAccount,
    activateSwitchAccount,
  } = useContext(context);

  /**
   * Gets the history object
   */
  const history = useHistory();

  /**
   * Gets the current location
   */
  const location = useLocation();

  /**
   * Gets user utils
   */
  const { isAccountSuspended, isUserWorker, isUserSimpleService } =
    useUserUtils();

  /**
   * Handles going to a route
   */
  const goTo = (path: Paths) => {
    if (history.location.pathname === path) return;
    history.push(path);
  };

  const goToDashboard = () => {
    history.push(Paths.Dashboard);
  };

  /**
   * Handles getting all the route paths
   */
  const getPaths = () => Object.values(Paths) as string[];

  /**
   * Handles going back
   */
  const goBack = () => history.goBack();

  /**
   * Checks if the user has accessed any blocked routes
   */
  const hasAccessedBlockedRoutes = () => {
    const isSuspended = isAccountSuspended;
    const isWorker = isUserWorker;

    /**
     * A different redirector is handling this case
     */
    if (isSuspended && isWorker) return false;

    if (
      isUserSimpleService &&
      (!location.pathname.includes("/dashboard/appointments") ||
        !location.pathname.includes("/dashboard/tyre-hotels"))
    ) {
      return true;
    }

    /**
     * When suspended only admin / operator can access account settings
     */
    if (
      isSuspended &&
      !location.pathname.includes("/dashboard/account-settings/")
    ) {
      return true;
    }
  };

  return {
    goTo,
    Paths,
    goBack,
    getPaths,
    expanded,
    toggleMenu,
    setExpanded,
    goToDashboard,
    switchingAccount,
    scrollContainerId,
    closeSwitchAccount,
    activateSwitchAccount,
    isNotAllowed: hasAccessedBlockedRoutes(),
    isSuspended: isAccountSuspended && isUserWorker,
  };
};

export { useNavigation, NavigationProvider };
