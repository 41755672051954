import { useState, useEffect } from "react";

/**
 * External imports
 */
import fileDownload from "js-file-download";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useSelector,
  useForm,
  useWatch,
  useUtils,
  useSearch,
  useReports,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "../index";

/**
 * Imports types
 */
import { UseReportsProps } from "../useReports";
import { FormBody } from "./useSuspendedWoReport.types";
import { FilterModel } from "../../types";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GenerateReportsPDFBody,
  GenerateReportsPDFOnSuccess,
  GenerateReportsPDFParams,
  GenerateReportsExcelBody,
  GenerateReportsExcelOnSuccess,
  GenerateReportsExcelParams,
} from "../useApi";

export const useSuspendedWoReport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [initRequest, setInitRequest] = useState(false);
  const [modelsInitialized, setModelsInitialized] = useState(false);
  const { userInitialized } = useSelector((state) => state.account);
  const { config } = useSelector((state) => state.reports);
  const { dispatchSnackbar, dispatchMessage, updateReportsConfig } =
    useActions();
  const { apiCalls } = useApi({ withCredentials: true });
  const { formatDate } = useUtils();
  const { formatActiveFilters, createFilter, getFieldType, parseQueryValue } =
    useFilterModelsUtils();
  const { order, orderBy, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();
  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();
  const debounce = useDebounce();

  const methods = useForm<FormBody>({
    defaultValues: {
      detailed: config ? config.advanced : false,
    },
    mode: "onChange",
    criteriaMode: "all",
  });

  const detailedReport = useWatch({
    control: methods.control,
    name: "detailed",
  });

  const useReportsProps: UseReportsProps = {
    type: "suspended-work-order",
    format: detailedReport ? "normal" : "simple",
    order,
    orderBy,
    onSuccess: () => {
      setInitRequest(true);
    },
  };

  const {
    loading,
    report,
    triggerSearch,
    setTriggerSearch,
    generateReport,
    setReport,
    setLoading,
    handleSearch,
    deleteFilter,
    resetFilters,
  } = useReports(useReportsProps);

  const onRequestPdfError: RequestOnError = () => {
    dispatchSnackbar({
      title: t("ReportPdf"),
      description: t("GeneratePdfReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  const onRequestExcelError: RequestOnError = () => {
    dispatchSnackbar({
      title: t("ReportExcel"),
      description: t("GenerateExcelReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  const downloadPdfReport = async (includeServices?: boolean) => {
    dispatchSnackbar({
      title: t("ReportPdf"),
      description: t("GenerateReportPdf"),
      variant: "loading",
    });

    const reqBody: GenerateReportsPDFBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    const params: GenerateReportsPDFParams = {
      type: "suspended-work-order",
      format: "normal",
      interval: "detailed",
    };

    if (includeServices) {
      reqBody.with_services = true;
    }

    const onSuccess: GenerateReportsPDFOnSuccess = (data) => {
      const fileName = `suspended_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.pdf`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("ReportPdf"),
        description: t("GenerateReportSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsPDF(
      params,
      reqBody,
      onSuccess,
      onRequestPdfError,
    );
  };

  const downloadExcelReport = async () => {
    dispatchSnackbar({
      title: t("ReportExcel"),
      description: t("GenerateReportExcel"),
      variant: "loading",
    });

    const reqBody: GenerateReportsExcelBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    const params: GenerateReportsExcelParams = {
      type: "suspended-work-order",
      format: "suspended",
      interval: "daily",
    };

    const onSuccess: GenerateReportsExcelOnSuccess = (data, response) => {
      const fileName = `suspended_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.xls`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("ReportExcel"),
        description: t("GenerateReportExcelSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsExcel(
      params,
      reqBody,
      onSuccess,
      onRequestExcelError,
    );
  };

  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      generateReport(filters, {
        reportType: "suspended-work-order",
        reportFormat: detailedReport ? "normal" : "simple",
      });
    }, 500);
  };

  const initializeFilterModels = () => {
    const models: FilterModel[] = [];
    const organization = getUserOrganization();
    const workOrderType = getDefaultWorkOrderType();

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return (
            model.field === "organization_id" ||
            model.field === "work_order_type_id"
          );
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    if (organization && workOrderType) {
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      const workOrderTypeFilterModel = createFilter({
        field: "work_order_type_id",
        selected: workOrderType.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
      models.push(workOrderTypeFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  const getReportTimeInterval = () => {
    const dateFilter = activeFilters.find(
      (filter) => filter.field === "finished",
    );

    const format = "yyyy.MM.dd";

    return {
      startDate: formatDate(
        dateFilter ? dateFilter.selected[0] : new Date(),
        format,
      ),
      endDate: formatDate(
        dateFilter ? dateFilter.selected[1] : new Date(),
        format,
      ),
    };
  };

  const syncLocalStorage = () => {
    const clone = cloneDeep(config) || {};
    clone["advanced"] = detailedReport;

    updateReportsConfig(clone);
  };

  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setReport(undefined);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
      } else {
        generateReport(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  useEffect(() => {
    if (initRequest) {
      handleSubmit(activeFilters);
      window.addEventListener("beforeunload", syncLocalStorage);

      return () => {
        syncLocalStorage();
        window.removeEventListener("beforeunload", syncLocalStorage);
      };
    }
    // eslint-disable-next-line
  }, [detailedReport]);

  useEffect(() => {
    if (userInitialized) initializeFilterModels();
    // eslint-disable-next-line
  }, [userInitialized]);

  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        generateReport(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  return {
    report,
    loading,
    methods,
    activeFilters,
    detailedReport,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    formatActiveFilters,
    getReportTimeInterval,
    downloadPdfReport,
    downloadExcelReport,
  };
};
