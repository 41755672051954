import { useState, useEffect } from "react";

/**
 * External imports
 */
import { findIndex, cloneDeep } from "lodash";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useSearch,
  useActions,
  useSelector,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { FilterModel, Guaranty, TableData } from "../../types";
import {
  GetGuarantiesOnSuccess,
  RequestOnError,
  GetGuarantiesQueryVariables,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const GuarantiesProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  /**
   * Initializes the trigger search flag
   */
  const [triggerSearch, setTriggerSearch] = useState(false);

  /**
   * Initializes the guaranties
   */
  const [guaranties, setGuaranties] = useState<Guaranty[]>([]);

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(true);

  /**
   * Initializes the table rows loading
   */
  const [tableRowsLoading, setTableRowsLoading] = useState(false);

  /**
   * Initializes the page count
   */
  const [pageCount, setPageCount] = useState(1);

  /**
   * Initializes the total guaranties
   */
  const [totalGuaranties, setTotalGuaranties] = useState(0);

  /**
   * Initializes the order by state
   */
  const [orderBy, setOrderBy] = useState("createdAt");

  /**
   * Initializes the order dir state
   */
  const [orderDir, setOrderDir] = useState<"asc" | "desc">("desc");

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the api calls
   */
  const { queries } = useApi({ withCredentials: true });

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Gets the message dispatcher
   */
  const { dispatchMessage } = useActions();

  /**
   * Gets the filter model utility hook
   */
  const {
    modelsToQueryVariables,
    formatActiveFilters,
    addOrReplaceFilter,
    createFilter,
    removeFilter,
    getFieldType,
    parseQueryValue,
    filtersToQueryString,
  } = useFilterModelsUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization } = useUserUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { defaultFilters, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Handles searching based on search value
   */
  const handleSearch = (searchValue: string) => {
    /**
     * Defines the search filter model
     */
    const searchFilter = createFilter({
      field: "quick_text",
      selected: searchValue,
      type: "like",
    });

    /**
     * Updates the active filters
     */
    const filterModels = addOrReplaceFilter(searchFilter, activeFilters);

    setLoading(true);
    searchGuaranties(filterModels);
    setActiveFilters(filterModels);
  };

  /**
   * Handles deleting a filter
   */
  const deleteFilter = (filter: FilterModel) => {
    const updatedFilters = removeFilter(filter, activeFilters);
    setActiveFilters(updatedFilters);

    debounce(() => {
      setPageCount(1);
      setLoading(true);
      setTriggerSearch(true);
    }, 1500);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setLoading(true);
    setActiveFilters(defaultFilters);
    setPageCount(1);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      searchGuaranties(filters);
    }, 500);
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return model.field === "organization_id";
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Creates the filter models
     */
    if (organization) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles searching for guaranties
   */
  const searchGuaranties = async (filters: FilterModel[]) => {
    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    if (organization) {
      setLoading(true);

      /**
       * Defines the query variables
       */
      const variables: GetGuarantiesQueryVariables = {
        organization_id: organization.id,
        ...modelsToQueryVariables(filters),
      };

      const queryString = filtersToQueryString(filters);
      history.push(`?${queryString}`);

      const onSuccess: GetGuarantiesOnSuccess = ({ guaranties }) => {
        setGuaranties(guaranties);
        setLoading(false);
      };

      /**
       * Defines the api call error callback
       */
      const onError: RequestOnError = (error) => {
        setLoading(false);
        dispatchMessage({
          message: error.errorMessage ? error.errorMessage : "Unknown Error",
          severity: "error",
          autoClose: 10000,
        });
      };

      await queries.getGuaranties(variables, onSuccess, onError);
    }
  };

  /**
   * Handles updating the guaranty in the table
   */
  const updateGuaranty = (guaranty: Guaranty) => {
    const index = findIndex(guaranties, { id: guaranty.id });
    const clonedGuaranties = cloneDeep(guaranties);
    clonedGuaranties[index] = guaranty;

    setGuaranties(clonedGuaranties);
  };

  /**
   * Handles updating the current page state
   */
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPageCount(page);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Handles sorting, updates states
   */
  const handleSort = (e: React.MouseEvent<any>, property: keyof TableData) => {
    const isAsc = orderBy === property && orderDir === "asc";

    setOrderDir(isAsc ? "desc" : "asc");
    setOrderBy(property as string);
    setTableRowsLoading(true);
    setTriggerSearch(true);
  };

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) {
      initializeFilterModels();
    }
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles searching for clients once filter models are set
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        searchGuaranties(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setLoading(false);
        setTableRowsLoading(false);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
        initializeFilterModels();
      } else {
        searchGuaranties(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    tableRowsLoading,
    orderBy,
    orderDir,
    totalGuaranties,
    pageCount,
    guaranties,
    activeFilters,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    formatActiveFilters,
    setPageCount,
    setTotalGuaranties,
    setGuaranties,
    handlePageChange,
    handleSort,
    updateGuaranty,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
