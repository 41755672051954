import { createContext } from "react";

/**
 * Imports types
 */
// import { TFunction } from "../../hooks";
import { UserRole } from "../../types";
import { Dispatch, SetStateAction } from "react";

/**
 * Defines the Create List Groups props interface
 */
export interface CreateListGroupsProps {
  // t: TFunction;
  expandList: (id?: number | undefined) => void;
  isSuspended: boolean;
}

/**
 * Defines the Sidebar list interface
 */
export interface ListItem {
  label: string;
  icon?: JSX.Element;
  onClick?: (id?: number) => void;
  list?: ListItem[];
  active?: boolean;
  disabled?: boolean;
  path: string;
  hiddenOnMobile?: boolean;
  allowedRoles?: UserRole[];
}

/**
 * Defines the list group interface
 */
export interface ListGroup {
  listGroupId: number;
  dividerAfter?: boolean;
  allowedRoles?: UserRole[];
  list: ListItem[];
}

/**
 * Defines the Provider Props Interface
 */
export interface ProviderProps {
  expanded: boolean;
}

/**
 * Defines the Provider Values Interface
 */
export interface ProviderValues {
  expanded: boolean;
  expandedListGroup: number;
  setExpandedListGroup: Dispatch<SetStateAction<number>>;
  expandList: (id?: number | undefined) => void;
  createListGroups: (props: CreateListGroupsProps) => ListGroup[];
}

/**
 * Defines the default values
 */
export const defaultValues: ProviderValues = {
  expanded: true,
  expandedListGroup: -1,
  setExpandedListGroup: () => {},
  expandList: () => {},
  createListGroups: (props) => [],
};

/**
 * Defines a context where the state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
export const context = createContext<ProviderValues>(defaultValues);
