import { useState, useEffect, useCallback } from "react";

/**
 * External imports
 */
import fileDownload from "js-file-download";
import { useLocation } from "react-router-dom";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports hooks
 */
import {
  useApi,
  useSelector,
  useUtils,
  useSearch,
  useReports,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports types
 */
import { UseReportsProps } from "../useReports";
import { FilterModel, ReportType } from "../../types";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GenerateReportsPDFBody,
  GenerateReportsPDFOnSuccess,
  GenerateReportsPDFParams,
  GenerateReportsExcelBody,
  GenerateReportsExcelOnSuccess,
  GenerateReportsExcelParams,
} from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const CompanyReportProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the translator
   */
  const { t } = useTranslation();
  const location = useLocation();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the snackbar and message dispatcher
   */
  const { dispatchSnackbar, dispatchMessage } = useActions();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the filter model utility hook
   */
  const {
    createFilter,
    removeFilterByField,
    sanitizeTotalizedFilters,
    getFieldType,
    parseQueryValue,
  } = useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const {
    order,
    orderBy,
    activeFilters,
    defaultFilters,
    setActiveFilters,
    setDefaultFilters,
    setOrderBy,
    setOrder,
  } = useSearch();

  /**
   * Initializes the totalized flag
   */
  const [totalized, setTotalized] = useState(true);

  /**
   * Initializes the waiting flag
   */
  const [waiting, setWaiting] = useState(false);

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Defines the use reports props
   */
  const useReportsProps: UseReportsProps = {
    type: "company-totaled",
    format: "normal",
    order,
    orderBy,
    onSuccess: (type?: ReportType) => {
      setTotalized(type === "company-totaled");
      setWaiting(false);
    },
  };

  /**
   * Gets the reports hook data
   */
  const {
    report,
    loading,
    triggerSearch,
    setReport,
    setLoading,
    generateReport,
    setTriggerSearch,
    isReportTotalized,
    handleSearch,
    deleteFilter,
  } = useReports(useReportsProps);

  /**
   * Gets utility functions
   */
  const { formatDate } = useUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Defines the api call error callback
   */
  const onRequestPdfError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("CompanyReportPdf"),
      description: t("GeneratePdfReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Defines the api call error callback
   */
  const onRequestExcelError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("CompanyReportExcel"),
      description: t("GenerateExcelReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Handles generating and downloading the pdf report
   */
  const downloadPdfReport = async () => {
    dispatchSnackbar({
      title: t("CompanyReportPdf"),
      description: t("GenerateCompanyReportPdf"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsPDFBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsPDFParams = {
      type: totalized ? "company-totalized" : "work-order",
      format: "normal",
      interval: "custom",
    };

    /**
     * Gets the static file name
     */
    const staticFileName = totalized
      ? "company_report_totalized"
      : "company_report";

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsPDFOnSuccess = (data, response) => {
      const fileName = `${staticFileName}_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.pdf`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("CompanyReportPdf"),
        description: t("GenerateCompanyReportSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsPDF(
      params,
      reqBody,
      onSuccess,
      onRequestPdfError,
    );
  };

  /**
   * Handles generating and downloading the excel report
   */
  const downloadExcelReport = async () => {
    dispatchSnackbar({
      title: t("CompanyReportExcel"),
      description: t("GenerateCompanyReportExcel"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsExcelBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsExcelParams = {
      type: totalized ? "company-totalized" : "work-order",
      format: "normal",
      interval: "custom",
    };

    /**
     * Gets the static file name
     */
    const staticFileName = totalized
      ? "company_report_totalized"
      : "company_report";

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsExcelOnSuccess = (data, response) => {
      const fileName = `${staticFileName}_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.xls`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("CompanyReportExcel"),
        description: t("GenerateCompanyReportExcelSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsExcel(
      params,
      reqBody,
      onSuccess,
      onRequestExcelError,
    );
  };

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    /**
     * Checks if the report totalization flag is active
     */
    const totalized = isReportTotalized(filters);

    /**
     * Defines the active filters
     */
    const activeFilters = totalized
      ? sanitizeTotalizedFilters(filters)
      : removeFilterByField("totalization", filters);

    setLoading(true);
    setActiveFilters(activeFilters);
    setWaiting(totalized);

    debounce(() => {
      generateReport(activeFilters, {
        reportType: totalized ? "company-totaled" : "work-order",
        reportFormat: "normal",
      });
    }, 500);
  };

  /**
   * Gets the default date range filter model value
   */
  const getDefaultDateRange = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() - 1;
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    return [formatDate(startDate), formatDate(endDate)];
  };

  /**
   * Checks if the report result should be rendered
   */
  const shouldRenderReportResult = useCallback(() => {
    return (!!report && !loading && !waiting) as boolean;
  }, [report, loading, waiting]);

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return (
            model.field === "organization_id" ||
            model.field === "work_order_type_id" ||
            model.field === "finished"
          );
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Gets the default work order type (Vulcanizare)
     */
    const workOrderType = getDefaultWorkOrderType();

    /**
     * Creates the filter models
     */
    if (organization && workOrderType) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      /**
       * Defines the work order type filter model
       */
      const workOrderTypeFilterModel = createFilter({
        field: "work_order_type_id",
        selected: workOrderType.id,
        type: "dropdown",
      });

      /**
       * Defines the date range filter model
       */
      const dateRangeFilterModel = createFilter({
        field: "finished",
        selected: getDefaultDateRange(),
        type: "range",
      });

      // /**
      //  * Defines the payment type filter model ( Fisa termeni )
      //  */
      // const paymentTypeFilterModel = createFilter({
      //   field: "payment_type_id",
      //   selected: 12,
      //   type: "dropdown",
      // });

      models.push(organizationFilterModel);
      models.push(workOrderTypeFilterModel);
      models.push(dateRangeFilterModel);
      // models.push(paymentTypeFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles resetting the filters
   */
  const resetFilters = () => {
    setActiveFilters(defaultFilters);
    debounce(() => {
      setTriggerSearch(true);
    }, 1000);
  };

  /**
   * Handles getting the time interval for the report
   */
  const getReportTimeInterval = () => {
    const dateFilter = activeFilters.find(
      (filter) => filter.field === "finished",
    );

    /**
     * Defines the date format
     */
    const format = "yyyy.MM.dd";

    return {
      startDate: formatDate(
        dateFilter ? dateFilter.selected[0] : new Date(),
        format,
      ),
      endDate: formatDate(
        dateFilter ? dateFilter.selected[1] : new Date(),
        format,
      ),
    };
  };

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setReport(undefined);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
      } else {
        generateReport(activeFilters, {
          reportFormat: "normal",
          reportType: totalized ? "company-totaled" : "work-order",
        });
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) initializeFilterModels();
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        generateReport(activeFilters, {
          reportFormat: "normal",
          reportType: "company-totaled",
        });
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    order,
    orderBy,
    report,
    waiting,
    loading,
    totalized,
    modelsInitialized,
    activeFilters,
    setReport,
    setOrder,
    setOrderBy,
    handleSubmit,
    handleSearch,
    resetFilters,
    deleteFilter,
    getReportTimeInterval,
    shouldRenderReportResult,
    downloadPdfReport,
    downloadExcelReport,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
