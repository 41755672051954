import { useState, useEffect } from "react";

/**
 * External imports
 */
import fileDownload from "js-file-download";
import { useLocation } from "react-router-dom";

/**
 * Imports hooks
 */
import {
  useApi,
  useSelector,
  useUtils,
  useSearch,
  useReports,
  useActions,
  useDebounce,
  useUserUtils,
  useTranslation,
  useFilterModelsUtils,
} from "..";

/**
 * Imports types
 */
import { UseReportsProps } from "../useReports";
import { FilterModel } from "../../types";

/**
 * Imports API types
 */
import {
  RequestOnError,
  GenerateReportsPDFBody,
  GenerateReportsPDFOnSuccess,
  GenerateReportsPDFParams,
  GenerateReportsExcelBody,
  GenerateReportsExcelOnSuccess,
  GenerateReportsExcelParams,
} from "../useApi";

/**
 * Provides state management for the DailyReports component
 */
export const useDailyReport = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();
  const location = useLocation();

  /**
   * Gets the account state
   */
  const { userInitialized } = useSelector((state) => state.account);

  /**
   * Gets the snackbar and message dispatcher
   */
  const { dispatchSnackbar, dispatchMessage } = useActions();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the filter model utility hook
   */
  const { formatActiveFilters, createFilter, getFieldType, parseQueryValue } =
    useFilterModelsUtils();

  /**
   * Gets the filter models from the search provider
   */
  const { order, orderBy, activeFilters, setActiveFilters, setDefaultFilters } =
    useSearch();

  /**
   * Defines the use reports props
   */
  const useReportsProps: UseReportsProps = {
    type: "work-order",
    format: "normal",
    order,
    orderBy,
  };

  /**
   * Gets the reports hook data
   */
  const {
    loading,
    report,
    triggerSearch,
    setTriggerSearch,
    generateReport,
    setReport,
    setLoading,
    handleSearch,
    deleteFilter,
    resetFilters,
  } = useReports(useReportsProps);

  /**
   * Gets utility functions
   */
  const { formatDate } = useUtils();

  /**
   * Gets the user utility functions
   */
  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();

  /**
   * Gets the debouncer
   */
  const debounce = useDebounce();

  /**
   * Initializes the models initialized flag
   */
  const [modelsInitialized, setModelsInitialized] = useState(false);

  /**
   * Handles submitting the filters modal form
   */
  const handleSubmit = (filters: FilterModel[]) => {
    setLoading(true);
    setActiveFilters(filters);

    debounce(() => {
      generateReport(filters);
    }, 500);
  };

  /**
   * Gets the default date range filter model value
   */
  const getDefaultDateRange = () => {
    return [formatDate(new Date()), formatDate(new Date())];
  };

  /**
   * Defines the api call error callback
   */
  const onRequestPdfError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("DailyReportPdf"),
      description: t("GeneratePdfReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Defines the api call error callback
   */
  const onRequestExcelError: RequestOnError = (error) => {
    dispatchSnackbar({
      title: t("DailyReportExcel"),
      description: t("GenerateExcelReportError"),
      variant: "error",
      closeAfter: 7000,
    });
  };

  /**
   * Handles generating and downloading the pdf report
   */
  const downloadPdfReport = async () => {
    dispatchSnackbar({
      title: t("DailyReportPdf"),
      description: t("GenerateDailyReportPdf"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsPDFBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsPDFParams = {
      type: "work-order",
      format: "normal",
      interval: "daily",
    };

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsPDFOnSuccess = (data, response) => {
      const fileName = `daily_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.pdf`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("DailyReportPdf"),
        description: t("GenerateDailyReportSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsPDF(
      params,
      reqBody,
      onSuccess,
      onRequestPdfError,
    );
  };

  /**
   * Handles generating and downloading the excel report
   */
  const downloadExcelReport = async () => {
    dispatchSnackbar({
      title: t("DailyReportExcel"),
      description: t("GenerateDailyReportExcel"),
      variant: "loading",
    });

    /**
     * Defines the request body
     */
    const reqBody: GenerateReportsExcelBody = {
      models: activeFilters,
      order_by: orderBy,
      order_dir: order,
    };

    /**
     * Defines the params
     */
    const params: GenerateReportsExcelParams = {
      type: "work-order",
      format: "normal",
      interval: "daily",
    };

    /**
     * Defines the api call success callback
     */
    const onSuccess: GenerateReportsExcelOnSuccess = (data, response) => {
      const fileName = `daily_report_${formatDate(
        new Date(),
        "yyyy-MM-dd",
      )}.xls`;

      fileDownload(data, fileName);

      dispatchSnackbar({
        title: t("DailyReportExcel"),
        description: t("GenerateDailyReportExcelSuccess"),
        variant: "success",
        closeAfter: 5000,
      });
    };

    await apiCalls.generateReportsExcel(
      params,
      reqBody,
      onSuccess,
      onRequestExcelError,
    );
  };

  /**
   * Returns the date range filter for the report
   */
  const getTodayDateRangeFilter = () => {
    return createFilter({
      field: "finished",
      selected: getDefaultDateRange(),
      type: "range",
    });
  };

  /**
   * Handles initializing the filter models
   */
  const initializeFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.forEach((value, key) => {
        const parsedValue = parseQueryValue(key, value);

        models.push(
          createFilter({
            field: key,
            selected: parsedValue,
            type: getFieldType(key),
          }),
        );
      });

      setActiveFilters(models);
      setDefaultFilters(
        models.filter((model) => {
          return (
            model.field === "organization_id" ||
            model.field === "work_order_type_id" ||
            model.field === "finished"
          );
        }),
      );
      setModelsInitialized(models.length > 0);
      return;
    }

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Gets the default work order type (Vulcanizare)
     */
    const workOrderType = getDefaultWorkOrderType();

    /**
     * Creates the filter models
     */
    if (organization && workOrderType) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      /**
       * Defines the work order type filter model
       */
      const workOrderTypeFilterModel = createFilter({
        field: "work_order_type_id",
        selected: workOrderType.id,
        type: "dropdown",
      });

      /**
       * Defines the date range filter model
       */
      const dateRangeFilterModel = getTodayDateRangeFilter();

      models.push(organizationFilterModel);
      models.push(workOrderTypeFilterModel);
      models.push(dateRangeFilterModel);
    }

    setActiveFilters(models);
    setDefaultFilters(models);
    setModelsInitialized(models.length > 0);
  };

  /**
   * Handles getting the time interval for the report
   */
  const getReportTimeInterval = () => {
    const dateFilter = activeFilters.find(
      (filter) => filter.field === "finished",
    );

    /**
     * Defines the date format
     */
    const format = "yyyy.MM.dd";

    return {
      startDate: formatDate(
        dateFilter ? dateFilter.selected[0] : new Date(),
        format,
      ),
      endDate: formatDate(
        dateFilter ? dateFilter.selected[1] : new Date(),
        format,
      ),
    };
  };

  /**
   * Removes the date range from the active filters display
   */
  const filterActiveFilters = (filters: FilterModel[]) => {
    return filters.filter((filter) => filter.type !== "range");
  };

  /**
   * Handles triggering a search after filter deletion
   */
  useEffect(() => {
    if (triggerSearch) {
      setTriggerSearch(false);
      if (activeFilters.length < 1) {
        setReport(undefined);
        dispatchMessage({
          severity: "error",
          message: t("PleaseProvideAtLeastOneFilter"),
        });
      } else {
        generateReport(activeFilters);
      }
    }
    // eslint-disable-next-line
  }, [triggerSearch]);

  /**
   * Initializes the filter models
   */
  useEffect(() => {
    if (userInitialized) initializeFilterModels();
    // eslint-disable-next-line
  }, [userInitialized]);

  /**
   * Handles generating the initial report based on default filters
   */
  useEffect(() => {
    if (modelsInitialized) {
      debounce(() => {
        generateReport(activeFilters);
      }, 500);
    }
    // eslint-disable-next-line
  }, [modelsInitialized]);

  return {
    report,
    loading,
    activeFilters,
    modelsInitialized,
    handleSubmit,
    resetFilters,
    deleteFilter,
    handleSearch,
    formatActiveFilters,
    filterActiveFilters,
    getReportTimeInterval,
    downloadPdfReport,
    downloadExcelReport,
  };
};
